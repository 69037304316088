import React from "react";
import bullets from "../../images/resource/bullets-certification.png";
import miniIFM from "../../images/resource/cert1.png";
import miniEAFIT from "../../images/resource/cert2.png";
import miniAMF from "../../images/resource/cert3.png";
import bannerAbout from "../../images/resource/banner-about.png"

const Certifications = () => {
  return (
    <>
      <div className="container-bannerAbout">
        <img className="bannerAbout" src={bannerAbout} alt="" />
        <img className="bannerAbout1" src={bannerAbout} alt="" />
      </div>
      <div className="certifications">
        <div className="titles-certification">
          <p id="section3">RECIBE</p>
          <h2 className="sec-title_heading">3 certificaciones</h2>
          <div className="bullets-certification">
            <img className="img-certification" src={bullets} alt="" />
          </div>
        </div>
        <div className="cert">
          <div className="items">
            <div className="item-text">
              <img className="img-cert-comp" src={miniIFM} alt="Logo IFM" />
              <h5>Instituto de medicina funcional</h5>
              <p className="cert-subtitle">(certificado de finalización)</p>
            </div>

            <div className="item-text">
              <img className="img-cert-comp" src={miniAMF} alt="logo AMF" />
              <h5>Academia de Medicina Funcional</h5>
            </div>

            <div className="item-text">
              <img className="img-cert-comp" src={miniEAFIT} alt="Logo EAFIT" />
              <h5>Universidad EAFIT</h5>
              <p className="cert-subtitle">(únicamente para médicos, aplica para Colombia)</p>
            </div>

            <div className="item-text">
              <img className="img-cert-comp" src='https://s3-amf.s3.us-east-1.amazonaws.com/Landing-AMF/Images/EMCS+Hor+Color.svg' alt="Logo ITM" />
              <h5>Tecnológico de Monterrey</h5>
              <p className="cert-subtitle">(únicamente para médicos, aplica para México)</p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-form" id="section4">
        <div className="certification-contact-text">
          <h3>Te contactamos</h3>
          <p className="about-one_text">
            Si necesitas más información <br /> personalizada no dudes en
            dejarnos <br />
            tus datos y te contactamos
          </p>
        </div>

        <div className="form-spacing" id="form">
          <iframe
            className="form-iframe-page"
            id="inlineFrameExample"
            title="Inline Frame Example"
            src="https://share.hsforms.com/1D4c9p5k9SoKamln28jXwPge92zh"
          ></iframe>
        </div>
      </div> */}
    </>
  );
};

export default Certifications;
