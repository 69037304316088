import React from 'react'

const Calendar = () => {
    
  return (
    <div className="container-calendar-wrap">
    <div className='container-calendar'>
        <img className='calendar' src='https://s3-amf.s3.us-east-1.amazonaws.com/Landing-AMF/Images/7+Pilares+de+abordaje_horizontal.png' alt='7 pilares desktop imagen' />
        <img className='calendar1' src='https://s3-amf.s3.us-east-1.amazonaws.com/Landing-AMF/Images/7+Pilares+de+abordaje_vertical.png' alt='7 pilares mobile imagen' /> 
    </div>
    </div>
  )
}

export default Calendar