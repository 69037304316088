import React from "react";
import { Link } from 'react-router-dom'
import pimg1 from '../../images/clients/1.png'
import pimg2 from '../../images/clients/2.png'
import pimg3 from '../../images/clients/3.png'
import pimg4 from '../../images/clients/4.png'
import pimg9 from '../../images/clients/9.png'


/* const partners = [
    {
        pImg: pimg1,
    },
    {
        pImg: pimg2,
    }
] */

const partners2 = [
    {
        pImg: pimg2,
    },
    {
        pImg: pimg1,
    },
    {
        pImg: pimg3,
    },
    {
        pImg: pimg9,
    }
]

const partners3 = [
    {
        pImg: pimg4,
    }
]

const PartnerSection = (props) => {

    return (

        <section className="clients-one">
            <div className="auto-container">
                {/* <div className="sec-title title-anim centered">
                    <div className="sec-title_title" id="section3">Aliados</div>
                    <h2 className="sec-title_heading">Agradecemos a nuestros amables socios</h2>
                </div> */}
                <div className="row clearfix centered">
                    {/* {partners.map((partner, pitem) => (
                        <div className="clients-one_column col-lg-5 col-md-5 col-sm-6" key={pitem}>
                            <img src={partner.pImg} alt="" />
                        </div>
                    ))} */}
                </div>
            </div>
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    
                    <h2 className="sec-title_heading">Certificado por: </h2>
                </div>
                <div className="row1 clearfix centered">
                    {partners2.map((partner, pitem) => (
                        <div className="clients-one_column col-lg-5 col-md-5 col-sm-6" key={pitem}>
                            <img src={partner.pImg} alt="" />
                        </div>
                    ))}
                </div>
            </div>
            
        </section>
    );
}

export default PartnerSection;