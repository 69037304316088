import React from "react";
/* import imgVer from '../../images/diplIFMvertical.png' */
import imgVer2 from "../../images/banner-about.png";
/* import modality from '../../images/modality.png' */
import modality2 from "../../images/modality2.png";

const PricingNew = () => {
  return (
    <>
      <div className="container-pricing container-rules">
        <div className="pricing-header">
          <p id="section2">ASEGURA TU LUGAR</p>
          <h2>Paquetes de estudio</h2>
        </div>
        <div className="pricing-container">
          {/* <div className="pricing-card">
            <h3 className="price-advisor1">Precio válido del <span> 5 de enero <br/> al 6 de marzo</span> de 2024</h3>
            <div className="card-price card-price_blue">
              <p className="plan1">Early bird</p>
              <img className="first-img-price" src={imgVer} alt="" />
              <img className="second-img-price" src={modality} alt="" />
              <p className="disacount-price">USD $ 3.400</p>
              <p className="aliance-price">gracias a nuestra alianza entre AMF y IFM</p>
              
              
              <p className="disclaimer">
              * El precio final en moneda local dependerá del tipo de cambio de su entidad financiera (conversión USD - dólares americanos vs. moneda local de pago)
              </p>
            </div>
          </div> */}
          <div className="pricing-card">
            <p className="disclaimerUp">
              <strong>
                Al inscribirte en el Diplomado en Medicina Funcional, accedes a
                beneficios exclusivos:
              </strong>{" "}
              Master Class con expertos, acceso al CIMF Virtual y un{" "}
              <strong>30% de descuento</strong> para inscribirte en el AIC
              organizado por el IFM.
              <strong>
                {" "}
                Impulsa tu carrera con herramientas de vanguardia y un
                aprendizaje sin límites.
              </strong>
            </p>
            <div className="card-price card-price_aqua">
              <p className="earlyBird">
                Precio etapa Justo a tiempo <br/>hasta el 21 de abril
              </p>
              <img className="first-img-price" src={imgVer2} alt="" />
              <img className="second-img-price" src={modality2} alt="" />
              <p className="disacount-price">USD $ 3.400</p>
              <p className="aliance-price">
                gracias a nuestra alianza entre AMF y IFM
              </p>
              <p className="real-price">USD $ 1.900</p>
              <p className="aliance-price1">
                Antes <span className="disacount-price1">USD $1.700</span>
              </p>
              <a
                href="https://edu.academiademedicinafuncional.info/producto/diplomado-medicina-funcional-2025-afmcp/"
                target="_blank"
                rel="noreferrer"
                class="btn-card-price"
              >
                Inscríbete
              </a>
              <div className="spacing-prices">
              <a
                href="https://academiademedicinafuncional.info/amfrec/facilidades-de-pago-programas-academicos/"
                target="_blank"
                rel="noreferrer"
                class="btn-card-price2"
              >
                Plan de Pagos
              </a>
              </div>
              
              <p className="disclaimer">
                * El precio final en moneda local dependerá del tipo de cambio
                de su entidad financiera (conversión USD - dólares americanos
                vs. moneda local de pago)
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingNew;
